.burger {
    position: relative;
    width: 50px;
    height: 22px;
    cursor: pointer;
    z-index: 2;
    right: 20px;
    top: 20px;
    position: fixed;
    top:20px;
    left: 20px;
    z-index: 3;
    &:before, &:after {
        content: "";
        width: 100%;
        height: 3px;
        background: #fff;
        position: absolute;
        right: 0;
        -webkit-transition: 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        -o-transition: 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transition: 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
    &:before {
        bottom:0;
    }
    &:after {
        top:0;
    }
}
  .openedBurger {
    &:before {
          bottom: 50%;
          -webkit-transform: rotate(-45deg) translate3d(0, 50%, 0);
          transform: rotate(-45deg) translate3d(0, 50%, 0);
      }
    &:after {
      top: 50%;
      -webkit-transform: rotate(45deg) translate3d(0, -50%, 0);
      transform: rotate(45deg) translate3d(0, -50%, 0);
  }
}
.menu {
    background: #636077;
    width: 10%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: 30px;
    font-family: verdana;
    position: fixed;
    font-size: 1.3em;//font-size: 60px;
    align-items: center;
    justify-content: center;
    z-index:2;
     left:-10%;
     opacity: 0;
     //transition: opacity 1s;
      a {
          width: 100%;
          text-align: center;
          height: 80px;
          line-height: 80px;
          text-decoration: none;
          color: white;
          font-size: 30px;
          .menuItem {
              width: 100%;
              text-align: center;
              height: 50px;
              line-height: 50px;
          }
           &:hover {
          color:#225527;
        }
      }
     
  }
  .showMenu {
    //   animation-name: move;
    //   animation-duration: 1s;
    //   animation-fill-mode: forwards;
      z-index: 2;
    opacity: 1;
    left:0;
  transition: left 1s;
    }
  .hideMenu {
    //   animation-name: hide;
    //   animation-duration: 1s;
    //   animation-fill-mode: forwards;      
    
     left:-10%;
  transition: left 1s;
    opacity: 0;
  }
  @keyframes move {
      from {left: -10%; opacity: 0;}
      to {left: 0; opacity: 1;}
  }
  @keyframes hide {
      from {left: 0; opacity: 1;}
      to {left: -10%; opacity: 0;}
  }

@media (max-width: 1440px) {
    .menu {
        width: 20%;
        left:-20%;
    }
    // @keyframes move {
    //     from {left: -20%;}
    //     to {left: 0;}
    // }
    // @keyframes hide {
    //     from {left: 0;}
    //     to {left: -20%;}
    // }
    .showMenu {
    opacity: 1;
    left:0;
    }
  .hideMenu {
    opacity: 0;
    left: -20%;
  }
}


@media (max-width: 1000px) {
    .menu {
      width: 40%;
      left:-40%;
      a {
          height: 115px;
          line-height: 115px;
          
          font-size: 50px;
      }
    }
    // @keyframes move {
    //     from {left: -40%;}
    //     to {left: 0;}
    // }
    // @keyframes hide {
    //     from {left: 0;}
    //     to {left: -40%;}
    // }
    .showMenu {
        opacity: 1;
        left:0;
    }
    .hideMenu {
        opacity: 0;
        left:-40%;
    }
}


@media (max-width: 768px) {
    .menu {
        width: 70%;
        left:-70%;
        a {
            height: 115px;
            line-height: 115px;
            font-size: 50px;
        }
    }
    // @keyframes move {
    //     from {left: -70%;}
    //     to {left: 0;}
    // }
    // @keyframes hide {
    //     from {left: 0;}
    //     to {left: -70%;}
    // }
     .showMenu {
    opacity: 1;
    left:0;
    }
  .hideMenu {
    opacity: 0;
    left:-70%;
  }
}