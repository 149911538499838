.contactSection {
	height: 100vh;
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	z-index: 1;    
	margin-bottom: 50px;
	.formTitle{
		text-align: center;
		font-size: 30px;
		margin-bottom: 20px;
		color: white;
	}
	.contactContainer {
		display: flex;
		width: 75%; // 90%;
		justify-content: space-evenly;
		margin: 0 auto;
		flex-direction: row-reverse;
		.formContainer {
			width: 40%;
			.form {
				input, textarea {
					width: 100%;
					border-radius: 5px;
					border: none;	
					padding: 10px;
					box-sizing: border-box;
					margin-bottom: 10px;
					background:#353535ad;//#353535;
					color: white;
					font-size: 20px;box-shadow: 2px 4px 8px 2px black;
    				background: #3e131a6b;
				}
				input, textarea {
					outline: none;
				}
				textarea {
					height: 200px;
				}
				.button {
					margin-top: 10px;
					button {
					border: none;
					padding: 10px;
					background: #33a0a0;
					color: white;
					border-radius: 3px;
					font-size: 20px;
					padding: 10px 20px;
					}
				}
			}
		}
		.socialMediaContainer {
			width: 55%;
			font-size: 35px;
			color: white;
			.socialHello {
				font-size:50px;
				margin-bottom: 30px;			
			}
			.socialMail {
				a {
						background: #3E131A;
					padding: 5px 10px;
					border-radius: 5px;
					cursor: pointer;
					box-shadow: 6px 5px 2px 3px black;
					text-decoration: none;
					color: white;
					}
			}
			.socialAppsPart {
				.socialAppsHello {
					display: flex;
				}
				.socialAppsApps {
					a {
						text-decoration: none;
						color: white;
					}
					margin-left: 20px;
					svg {
						font-size: 40px;
					}
				}
				.cvGrab {
            .downlCVButton {
              background: #3E131A;
              padding: 5px 10px;
              border-radius: 5px;
              cursor: pointer;
              box-shadow: 6px 5px 2px 3px black;
              text-decoration: none;
              color: white;
              transition: color 1s;
            }
            .downlCVButton:hover {
              color:#3E131A;
            }
				}
			}
		}
	}
}

@media (max-width: 1440px) {
	.contactSection {
		.contactContainer {
			width: 95%;
			.socialMediaContainer {
				.socialHello {
					font-size:40px;
				}
				.socialMail {
					font-size: 30px;
				}
				.socialAppsPart {
					font-size:30px;
					.socialAppsHello {
						margin-top: 20px;
					}
					.cvGrab {
						line-height: 45px;
					}
				}
			}
		}
	}
}

@media (max-width: 880px) {
	.contactSection {
		height: auto;
		text-align: center;
		.contactContainer {
			flex-direction: column-reverse;
			.formContainer {
				width: 90%;
				margin: 0 auto;
			}
			.socialMediaContainer {
				width: 90%;
				margin: 0 auto;
				.socialAppsHello {
					justify-content: center;
				}
			}
		}
	}
}
@media (max-width: 500px) {
	.contactSection {
		margin-bottom: 20px;
		.contactContainer {
			.socialMediaContainer {
				.socialHello {
					font-size: 27px;
				}
				.socialMail {
					font-size: 22px;
					a {
						padding: 3px 5px;
					}
				}
				.socialAppsPart {
					font-size: 22px;
					.socialAppsHello {
						display: flex;
						flex-direction: column;
						align-items: center;
						height: 90px;
						justify-content: space-between;
					}
					.socialAppsApps {
						margin-left: 0;
						svg {
							margin: 5px;
						}
					}
					.cvGrab {
						line-height: 40px;
						text-align: center;
					}
				}
			}
		}
	}
}