:global {
  * {
	font-family: 'Raleway', sans-serif;
}

body, html {
	width:100%;
	height:100%;
	margin:0;
}
#root {
	width:100%;
	height:100%;
  .App {
    background: #273043;
  }
	.main {
		width:100%;
		height:100%;
	}
}
}