.portfolioSection {
	// height:100vh;
	// height: fit-content;
	// background: #273043;
	// padding: 3% 0%;
  width: 90%;
  margin: 0 auto;
			.singleProject {
				// background: #1A3E13;
				// background: #133E22;
				// background: #3E131A;
				background: #3e131a6b;
				box-shadow: 2px 4px 8px 2px black;
				margin: 0 auto;
				position: relative;
				width: 100%;
				z-index: 1;
				margin-bottom: 20px;
				display: flex;
				align-items: center;
				height: 370px;//260px;
				.mobileTitle { 
					display: none;
					width: 100%;
					text-align: center;
					z-index: 1;
					font-size: 40px;
					font-weight: 900;
					color: white;
					margin-bottom: 20px;
					font-size: 27px;
				}
				.bodyLeft {
					max-width: 100%;
					width: 40%;
					position: relative;
					display: flex;
					justify-content: center;
					height: 370px;
					align-items: center;
					.bodyLeftVideo {
						z-index: 1;
						width: 100%;
						padding: 0 20px;
						box-sizing: border-box;
						height: 100%;
						height: 80%;
						display: flex;
						justify-content: center;
						video {
							// height: 370px;
							height: 100%;
						}
					}
				}
				.bodyRight {
					display: flex;
					padding: 10px;
					box-sizing: border-box;
					width: 60%;
					height: 90%;
					
					.bodyRightDescr {
						
						z-index: 1;
						display: flex;
						width: 100%;
						padding: 0 20px;
						box-sizing: border-box;
						position: relative;
						.bodyRightDescrDescr {
							width:80%;
							font-size: 25px;
							color: white;
							line-height: 35px;
							display: flex;
							flex-direction: column;
							justify-content: space-around;
							align-items: center;
							padding: 0 5px;
							box-sizing: border-box;
							.title {
								width: 100%;
								text-align: center;
								z-index: 1;
								font-size: 40px;
								font-weight: 900;
							}
							.bodyRightDescrCheck {
								display: flex;
								width: 40%;
								justify-content: space-between;
								a {
									text-decoration: none;
									background: #131a3d;
									box-shadow: 2px 3px 3px 3px #000;
									padding: 5px 30px;
									cursor: pointer;
									color: #fff;
								}
							}
						}
						.bodyRightDescrDescr:before {
							content: '';
							color: white;
							width: 2px;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							background: white;
						}
						.bodyRightDescrStack {
							width: 20%;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;    
							position: relative;
							// .stackTitle {
							// 	display: none;
							// 	color: white;
							// 	font-size: 40px;
							// 	margin-bottom: 20px;
							// }
							.stackList {
								display: flex;
								align-items: center;
								flex-direction: column;
								img {
									max-width: 25%;
								}
								span {
									color:white;
									font-size:30px;
								}
							}
						}
						.bodyRightDescrStack:before {
							content: '';
							// color: white;
							width: 2px;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							background: white;
						}
					}	
				}
	}
		
}
@media (max-width: 1440px) { 
	.portfolioSection {
				.singleProject {
					.bodyLeft {
						width: 55%;
					}
					.bodyRight {
						width: 55%;
						.bodyRightDescr {
							padding: 0 10px;
							.bodyRightDescrDescr {
								//width: 65%;
								.bodyRightDescrCheck {
									width: 60%;
								}
							}
							.bodyRightDescrStack {
								//width: 35%;
								.stackList {
									span {
										font-size: 24px;
									}
								}
							}
						}
					}
				}
	}
}
@media ( max-width: 1024px) {
	.portfolioSection {
		height: auto;
		margin-bottom: 100px;
		margin-top: 50px;
				.singleProject {
					flex-direction: column;
					padding: 20px 0;
					height: auto;
					height: 1000px;
					height: auto;
					.mobileTitle {
						display: block;
					}
					.bodyLeft {
						width: 100%;
						height: 30%;
						.bodyLeftVideo {
							height: unset;
							max-height: unset;
							video {
								width: 100%;
							}
						}
					}
					.bodyRight {
						width: 90%;
						height: 70%;
						.bodyRightDescr {
							flex-direction: column;
							align-items: center;
							justify-content: space-around;
							.bodyRightDescrDescr {
								font-size: 20px;
								margin: 10px 0;
								width: 100%;
								.title {
									display: none;
								}
								.bodyRightDescrDescrText {
									margin-bottom: 20px;
									font-size: 30px;
									width: 100%;
									font-size: 22px;
								}
								.bodyRightDescrCheck {
									width: 35%;
								}
								
							}
							.bodyRightDescrDescr:before { 
								display: none;
							}
							
							.bodyRightDescrStack {
								width: 100%;
								.stackList {
									display: none;
									width: 70%;
									flex-direction: row;
									justify-content: space-between;
									padding: 20px 70px;
									margin-top: 20px;
									box-shadow: 3px 3px 6px 6px black, -3px -3px 6px 6px black;
									width: fit-content;
									span {
										font-size: 25px;
										margin-left: 30px;
									}
									span:nth-of-type(1) {
										margin-left: 0;
									}
								}
							}
							.bodyRightDescrStack:before {
								display:none;
							}
						}
					}
				}
	}
}

@media ( max-width: 768px) {
	.portfolioSection {
				.singleProject {
					.bodyRight {
						.bodyRightDescr {
							.bodyRightDescrDescr {
								width: 100%;
								padding: 30px 0;
								.bodyRightDescrCheck {
									width: 45%;
								}
							}
							.bodyRightDescrStack {
								.stackList {
									padding: 20px 10px;
								}
							}
						}
					}
				}
	}

}

@media ( max-width: 600px) {
	.portfolioSection {
				.singleProject {
					.bodyRight {
						.bodyRightDescr {
							.bodyRightDescrDescr {
								.bodyRightDescrCheck {
									width: 60%;
								}
							}
							.bodyRightDescrStack {
								.stackList {
									flex-direction: column;
									span {
										margin:3px 10px;
									}
								}
							}
					}
				}
			}
		}

}
@media ( max-width: 500px) {
	.portfolioSection {
				.singleProject {
					.bodyRight {
						.bodyRightDescr {
							.bodyRightDescrDescr {
								.bodyRightDescrCheck {
									width: 100%;
								}
							}
						}
					}
				}
	}
}

@media (max-height: 900px) { 

	.portfolioSection {
		height: auto;
		
	}

}