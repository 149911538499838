.aboutSection {
    height: 100vh;
    width:100%;
    background: #c94b4b; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #c94b4b, #4b134f); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #c94b4b, #4b134f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background:#273043;
    .aboutBlock {
        flex-direction: column;
        width: 90%;
        margin: 0 auto;
        display: flex;
        position: relative;
        padding: 0px 0px 45px 0px;
        box-sizing: border-box;
        background: #3e131a6b;
        box-shadow: 2px 4px 8px 2px black;
        z-index: 1;
        .aboutBlockTop {
            z-index: 1;
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: flex-start;
            width: 90%;
            margin: 0 auto;
            padding: 20px 0;
            .photo {
                text-align: center;
                z-index: 1;
                width: 40%;
                img {
                    max-width: 45%;
                    border-radius: 50%;
                }
            }
            .text {
                z-index: 1;
                width: 60%;
                color: white;
                font-size: 35px;
            }
        }
        .stackBlock::before {
            content: '';
            color: white;
            width: 100%;
            height: 2px;
            position: absolute;
            top: 0;
            left: 0;
            background: white;
        }
        .stackBlock {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            height: 283px;
            justify-content: space-between;
            width: 90%;
            margin: 0 auto;
            flex-direction: column;
            color: white;
            font-size: 35px;
            .iKnow {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                height: 200px;
                .iKnowTitle {
                    margin:20px 0;
                }
                .iKnowContent {
                    display: flex;
                    width: fit-content;
                    flex-wrap: wrap;
                    justify-content: center;
                    font-size: 25px;
                    span {
                        margin-left: 25px;
                    }
                    span:nth-of-type(1) {
                        margin-left: 0px;
                    }
                }
                
            }
            .familierWith  {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                // height: 200px;
                .familierWithTitle {
                    //margin:20px 0;
                    display: none;
                }
                .familierWithContent {
                    display: flex;
                    width: fit-content;
                    flex-wrap: wrap;
                    justify-content: center;
                    font-size: 25px;

                    span {
                        margin-left: 25px;
                    }
                    span:nth-of-type(1) {
                        margin-left: 0px;
                    }
                }
            }
        }
        
    }
    // display: none;
}
@media (max-width: 1440px) {
   .aboutSection {
        .aboutBlock {
            .aboutBlockTop {
                .photo {
                    img {
                        max-width: 60%;
                    }
                }
            }
        }
    }
}
@media (max-width: 1024px) {
    .aboutSection {
        .aboutBlock {
            .aboutBlockTop {
                flex-direction: column;
                .text {
                    width: 100%;
                    font-size: 30px;
                }
                .photo {
                    img {
                        max-width: 80%;
                    }
                }
            }
            .stackBlock {
            
                height: 250px;
                .iKnow {
                    .iKnowTitle {
                        font-size: 30px;
                    }
                    .iKnowContent {
                        font-size: 20px;
                        line-height: 30px;
                        
                    }
                }
                .familierWith {
                    .familierWithContent {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) { 
    .aboutSection {
        .aboutBlock {
            .aboutBlockTop {
                .text {
                    font-size: 20px;
                }
                .photo {
                
                    img {
                        max-width: 100%;
                    }
                }
            }
            .stackBlock {
                .iKnow {
                    .iKnowTitle {
                        font-size: 20px;
                    }
                    .iKnowContent {
                        font-size: 15px;
                        
                        span {
                            margin-left: 10px;
                        }
                    }
                }
                .familierWith {
                    .familierWithContent {
                        font-size: 15px;
                        line-height: 30px;
                        span {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}
