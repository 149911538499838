.welcomeScreen {
    z-index: 1;
    width:100%;
    height:100vh;
    background: #c94b4b; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #c94b4b, #4b134f); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #c94b4b, #4b134f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: #273043;
    .particles {
        height: 100%;
        width:100%;
    }
    .title {
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translate(-10%, -50%);
        color: white;
        z-index: 1;
        .name  {
            font-size: 7em;
            line-height: 100px;
            margin-bottom: 40px;
            user-select: none;
            .nameone {
                background: -webkit-linear-gradient(#ffffff, #30354c);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            .nametwo {
                background: -webkit-linear-gradient(#ffffff, #30354c);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        .job {
            font-size: 2.3em;
            user-select: none;
        }
    }
}


canvas {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
}


@media (max-width: 1000px) { 

    .welcomeScreen {
        .title {
            .name  {
                margin-bottom: 40px;
            }
            .job {
                font-size: 2.3em;
            }
        }
    }
}

@media (max-width:768px) {

    .welcomeScreen {
        
        .title {
            .name {
                font-size: 5em;
                line-height: 75px;
            }
            .job {
                font-size: 1.6em;
            }
        }
    }
}
@media (max-width: 500px) { 
    .welcomeScreen {
        .title {
            top: 50%;
            left: 40%;
            transform: translate(-40%,-50%);
            .name  {
                font-size: 3.5em;
                line-height: 60px;
                margin-bottom: 35px;
            }
            .job {
                font-size: 1.6em;
            }
        }
    }
 }